
@keyframes extend_appear {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes extend_appear_y {
    from {
        transform: translateY(5%);
        opacity: 0;
    }
}

@keyframes extend_disappear {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes appear_slide {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes window_appear {
    0% {
        transform: translate(0%, 5%) scale(0.95);
        opacity: 0;
    }

    100% {
        transform: translate(0%, 0%) scale(1);
        opacity: 1;
    }
}

@keyframes height-grow {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes login-appear {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes login-disappear {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    65% {
        opacity: 0;
        transform: scale(0.75);
    }

    100% {
        opacity: 0;
    }
}

@keyframes wrapper-appear {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes success-appear {
    0% {
        background-size: 0px;
    }

    100% {
        background-size: 24px;
    }
}

@keyframes spinner {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}

@keyframes spinner-2 {
    0% { opacity: 0; transform: scale(1); }
    75% { opacity: 1; transform: scale(0.5); }
    100% { opacity: 0; transform: scale(1); }
}

@keyframes notify {
    from { 
        opacity: 0;
        transform: translateY(-25%) scale(0.9);
    }
}

@keyframes notify_leave {
    to {
        opacity: 0;
        transform: scale(0.8);
    }
}

@keyframes notify_text_appear {
    from {
        opacity: 0;
        transform: translateX(-5%)
    }
}

@keyframes fluctuate {
    0% {
        filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
    }

    50% {
        filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.2));
    }

    100% {
        filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
    }
}

@keyframes fluctuate_success {
    0% {
        filter: drop-shadow(0 0 5px rgba(20, 88, 20, 0.75));
    }

    50% {
        filter: drop-shadow(0 0 5px rgba(20, 88, 20, 0.2));
    }

    100% {
        filter: drop-shadow(0 0 5px rgba(20, 88, 20, 0.75));
    }
}

@keyframes width-grow {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

@keyframes bake-pie {
    from {
        transform: rotate(0deg) translate3d(0,0,0) scale(0)
    }
}

@keyframes present-pie {
    from {
        width: 150px;
        height: 150px;
    }
}

@keyframes selection-slide {
    from {
        opacity: 0;
        transform:scale(0.8);
    }
}

@keyframes selection-remove {
    to {
        opacity: 0;
        transform: scale(0.8);
    }
}

@keyframes options-appear {
    from {
        opacity: 0;
        transform: translateY(5%);
    }
}

@keyframes support_grow {
    100% {
        border-radius: 20px;
        width: var(--support-width);
        height: var(--support-height);
    }
}

@keyframes support_small {
    0% {
        border-radius: 20px;
        width: var(--support-width);
        height: var(--support-height);
    }

    100% {
        border-radius: 50%;
        width: 54px;
        height: 54px;
    }
}

@keyframes dealer_appear {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes dealer_disappear {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes hover-appear {
    from {
        opacity: 0;
        transform: translateY(-5%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes hover-disappear {
    to {
        opacity: 0;
        transform: translateY(-10%);
    }
}

@keyframes arrow-bounce-top {
    50% {
       transform: translateY(-80%); 
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes filehint-slide {
    from {
        opacity: 0;
        transform: translate(-50%, -100%) scale(0.8);
    }
}

@keyframes empty-appear {
    from {
        opacity: 1;
        transform: translateX(-2%);
    }
}

@keyframes empty-pop {
    10% {
        transform: translate(-40px, -10px) rotate(-10deg);
    }

    20% {
        transform: translate(30px, -5px) rotate(-20deg);
    }

    30% {
        transform: scale(1.05) translate(-20px, 0) rotate(-30deg);
    }

    50% {
        transform: scale(1) translate(20px, -10px) rotate(-40deg);
    }

    60% {
        transform: scale(1.05) translate(-50px, 0px) rotate(-30deg);
    }

    70% {
        transform: scale(1) translate(0, -10px) rotate(-20deg);
    }

    80% {
        transform: scale(1.05) translate(0, 0) rotate(-10deg);
    }
}

@keyframes item-appear {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes update-bounce {
    0% {
        transform: scale(1);
        opacity: 0.75;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0.75;
    }
}

@keyframes error-popup {
    from {
        transform: translateY(-25%);
        opacity: 0;
    }
}

@keyframes dropdown-appear {
    0% {
        opacity: 0;
        transform: scale(0.98)
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes droppable-appear {

}

@keyframes particle-grow {
    50% {
        opacity: 0;  
    }

    100% {
        opacity: 0;
        width: 150%;
        height: 0;
        padding-bottom: 150%;
    }
}

@keyframes ai-remove {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -10%);
    }
}