.sidebar__item-img {
    min-width: 20px;
    min-height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    filter: invert(1);
    transition: filter 0.25s ease;
    pointer-events: none;
}

.sidebar__item .sidebar__item-img {
    margin-right: 15px;
}

/* .sidebar-hidden .sidebar__item-img {
    margin-right: 20px;
} */

.sidebar-hidden .sidebar__hide {
    transform: rotate(180deg);
    margin: auto;
}

.sidebar__item:hover .sidebar__item-img {
    filter: invert(0);
}

.img-default {
    filter: invert(0)
}

.sidebar-save {
    background-image: url("../images/icons/sidebar-save.png");
}

.sidebar-print {
    background-image: url("../images/icons/sidebar-print.png");
}

.sidebar-credit {
    background-image: url("../images/icons/sidebar-credit.png")
}

.sidebar-contract {
    background-image: url("../images/icons/sidebar-contract.png");
}

.sidebar-return {
    background-image: url("../images/icons/sidebar-return.png");
}

.sidebar-remove {
    background-image: url("../images/icons/sidebar-remove.png");
}

.sidebar-submit {
    background-image: url("../images/icons/sidebar-submit.png");
}

.sidebar-deny {
    background-image: url("../images/icons/sidebar-deny.png");
}

.sidebar-bank {
    background-image: url("../images/icons/sidebar-bank.png");
}

.sidebar-reports {
    background-image: url("../images/icons/sidebar-reports.png");
}

.sidebar-reports-black {
    background-image: url("../images/icons/sidebar-reports-black.png");
}

.sidebar-refresh {
    background-image: url("../images/icons/sidebar-refresh.png");
}

.sidebar-saveclose {
    background-image: url("../images/icons/sidebar-saveclose.png");
}

.sidebar-counter {
    background-image: url("../images/icons/sidebar-counter.png");
}

.sidebar-link {
    background-image: url("../images/icons/sidebar-link.png");
}

.sidebar-docusign {
    background-image: url("../images/icons/contract-docusign.png");
}

.sidebar-verify {
    background-image: url("../images/icons/sidebar-verify.svg");
}

.sidebar-rebook {
    background-image: url("../images/icons/sidebar-rebook.svg");
}

.sidebar-vehicle {
    background-image: url("../images/icons/sidebar-vehicle.png");
}

.icon-csv {
    background-image: url("../images/icons/extensions/csv.png");
}

.icon-api {
    background-image: url("../images/icons/account-api.png");
}

.icon-link {
    background-image: url("../images/icons/icon-link.svg");
    filter: invert(1);
}

.icon-duplicate {
    background-image: url("../images/icons/account-programs.png");
    filter: invert(0);
}

.icon-dashboard {
    background-image: url("../images/icons/account-dashboard.png");
}
.icon-settings {
    background-image: url("../images/icons/icon-settings.svg");
}
.icon-subaccounts {
    background-image: url("../images/icons/account-subaccounts.png");
}
.icon-dealers {
    background-image: url("../images/icons/account-dealers.png");
}
.icon-programs {
    background-image: url("../images/icons/account-programs.png");
}
.icon-website {
    background-image: url("../images/icons/account-website.png");
}
.icon-payments {
    background-image: url("../images/icons/account-payments.png");
}
.icon-fields {
    background-image: url("../images/icons/account-fields.png");
}
.icon-edit {
    background-image: url("../images/icons/chart-edit.png");
}
.icon-delete {
    background-image: url("../images/icons/remove-2.png");
}
.icon-warning {
    background-image: url("../images/icons/icon-warning-secondary.png");
}
.icon-positive {
    background-image: url("../images/icons/icon-warning-positive.png");
}

.icon-deal {
    background-image: url("../images/icons/deal/category-deal.svg");
}

.icon-lender {
    background-image: url("../images/icons/deal/category-lender.svg");
}

.icon-credit {
    background-image: url("../images/icons/deal/category-credit.svg");
}

.icon-comments {
    background-image: url("../images/icons/deal/category-comments.svg");
}

.icon-logs {
    background-image: url("../images/icons/deal/category-logs.svg");
}

.icon-servicing {
    background-image: url("../images/icons/deal/category-servicing.svg");
}

.icon-return {
    background-image: url("../images/icons/icon-return.png");
}

.icon-login {
    background-image: url("../images/icons/login.png");
}

.icon-admin {
    background-image: url("../images/icons/profile-admin.png");
}

.icon-logout {
    background-image: url("../images/icons/profile-logout.png");
}

.icon-send {
    background-image: url("../images/icons/icon-send.png");
}

.icon-images {
    background-image: url("../images/icons/icon-images.png");
}

.icon-add {
    background-image: url("../images/icons/add.png")
}

.icon-experian {
    background-image: url("../images/icons/bureau/experian.png");
}

.icon-transunion {
    background-image: url("../images/icons/bureau/transunion.png");
}

.icon-remove {
    background-image: url("../images/icons/btn-remove.svg");
}

.icon-generate {
    background-image: url("../images/icons/buttons/generate.svg");
}

.icon-save {
    background-image: url("../images/icons/buttons/save.svg");
}

.icon-update {
    background-image: url("../images/icons/buttons/update.svg")
}