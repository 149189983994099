@media only screen and (max-width: 1800px) {
    .body-label {
        font-size: 12px;
    }

    .contract__item {
        font-size: 10px;
    }

    .payments-header span {
        font-size: 10px;
    }

    .payments-item span {
        font-size: 8px;
    }

    .sidebar__item-img {
        width: 20px;
        height: 20px;
    }

    .sidebar__item {
        font-size: 14px;
    }

    .popup-input-wide {
        width: 150px;
    }

    .sz-mr {
        width: 40px !important;
    }
    
    .sz-xs {
        width: 100px !important;
    }
    
    .sz-sm {
        width: 100px !important;
    }
    
    .sz-lg {
        width: 200px;
    }
    
    .sz-xl {
        width: 250px;
    }
    
    .sz-xxl {
        width: 450px !important;
    }
    
    .sz-md {
        width: 120px !important;
    }

    .popup__section-header {
        font-size: 10px;
    }

    .dropdown-text {
        font-size: 14px;
    }

    .selection-text {
        font-size: 14px;
    }

    .servicing-content {
        flex-direction: column-reverse;
    }

    .servicing-stat {
        width: 100px;
        font-size: 10px;
    }
    
    .file-approved::after, .file-denied::after {
        width: 14px;
        height: 14px;
    }
}

@media only screen and (max-width: 1300px) {
    :root {
        --standart-input-width: 140px;
    }

    .servicing-dashboard {
        justify-content: center;
    }

    .servicing-stat {
        gap: 2px;
    }

    /* .servicing-stats {
        margin: 0;
        width: auto;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    } */

    #term_count {
        margin-left: 0;
    }

    .support-image {
        width: 32px;
        height: 32px;
    }

    .valuation-item {
        font-size: 12px;
    }
    .dropdown-text {
        font-size: 14px;
    }

    .input__text {
        font-size: 12px;
    }

    .servicing-block {
        overflow: auto;
    }

    .popup__section {
        flex-wrap: wrap;
    }

    .popup__section-header {
        font-size: 8px;
    }

    .checkbox__text {
        font-size: 12px
    }

    .input-wrap .input {
        text-align: left;
        padding-right: 0px;
    }

    .deal__status {
        font-size: 10px;
        padding: 5px;
    }
    
    .popup-center {
        font-size: 12px;
    }

    .resp-height-auto {
        height: auto !important;
    }

    .resp-flx-column {
        flex-direction: column !important;
    }

    .input-adder:before {
        right: 110px;
    }

    .input-minus:before {
        right: 109px;
    }

    .calc__header-text {
        font-size: 20px;
    }

    .lender-text {
        font-size: 14px;
    }

    ctm-select {
        font-size: 10px;
        margin: 0;
    }

    .fixed-height {
        height: 100%;
    }

    .top__item {
        font-size: 12px;
    }

    .vehicle__name {
        font-size: 12px;
    }

    .vehicle__spec {
        font-size: 10px;
    }

    .sz-mr {
        width: 30px !important;
    }
    
    .sz-xs {
        width: 100px !important;
    }
    
    .sz-sm {
        width: 100px !important;
    }

    .sz-md {
        width: 100px !important;
    }
    
    .sz-lg {
        width: 150px !important;
    }
    
    .sz-xl {
        width: 200px !important;
    }
    
    .sz-xxl {
        width: 350px !important;
    }
    
    .header__name {
        font-size: 14px;
    }

    .dropdown__header-text {
        font-size: 14px;
    }

    .tracker__icon {
        background-size: 24px;
    }

    .notify__icon {
        width: 24px;
        height: 24px;
    }

    .notify__title {
        font-size: 14px;
    }

    .notify__body {
        font-size: 10px;
    }

    .sidebar-section {
        width: 30%;
    }

    #list-search {
        min-width: auto;
    }

    .input__combobox {
        width: 100%
    }

    .text-center {
        font-size: 10px;
    }

    .collaterals__warning {
        min-width: 200px;
    }
    
    .calc__header {
        flex-direction: column;
        gap: 20px;
    }
}

@media only screen and (max-height: 600px) {
    :root {
        --support-height: 440px;
    }
}

@media only screen and (max-width: 1000px) {
    :root {
        --header-height: 40px;
        --support-width: 300px;
    }

    .dashboard__empty {
        text-align: center;
    }

    .msg-unsaved {
        font-size: 10px;
    }

    .window-small, .window-huge, .window__panel, .window__panel-2 {
        width: 100vw !important;
        height: 100vh !important;
    }

    .panel__list, .panel__list-2 {
        display: flex;
        justify-content: center;
    }
    
    .advanced-wrapper, .summary-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown {
        padding: 20px;
    }

    .dropdown__panel {
        height: 100px;
    }

    .panel-center {
        padding-top: 25px;
    }

    .dropdown-img img {
        width: 16px;
        height: 16px;
    }

    .info-wrapper {
        gap: 10px;
    }

    .file__desc {
        font-size: 8px;
    }

    .files-icon {
        width: 16px;
        height: 16px;
    }

    .files-title {
        font-size: 10px;
    }

    .files-required {
        font-size: 10px;
        font-weight: 500;
    }
    
    .file-approved::after, .file-denied::after {
        width: 12px;
        height: 12px;
    }

    .comments-container {
        width: 100%;
    }

    .nav__link {
        font-size: 10px;
    }

    .profile__company {
        font-size: 8px;
    }

    .profile__extend {
        width: 16px;
        height: 16px;
    }

    .support-title {
        font-size: 16px;
    }

    .input-file {
        font-size: 10px;
        height: 30px;
    }

    .input-file::after {
        width: 16px;
        height: 16px;
    }

    .payments-header span {
        font-size: 8px;
    }

    .payments-item span {
        font-size: 6px;
    }

    .valuation-item {
        font-size: 10px;
    }

    .advanced-wrapper {
        border: 1px solid white;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .dropdown-text {
        font-size: 10px;
    }

    .header__name {
        font-size: 10px;
    }

    .deal__status {
        font-size: 8px;
    }

    .deals__deal {
        padding: 10px;
        height: 60px;
    }

    .deals-title {
        padding: 10px;
    }

    .sidebar__item-img {
        width: 12px;
        height: 12px;
    }

    .sidebar__item {
        font-size: 10px;
    }

    .top__item {
        font-size: 12px;
    }

    .vehicle__name {
        font-size: 10px;
    }

    .vehicle__spec {
        font-size: 8px;
    }

    .main-section {
        flex-direction: column;
    }

    .calc-wrapper, .advanced-wrapper, .summary-wrapper {
        width: 100%;
        margin: 10px 0 0 0;
    }

    .sidebar-hidden {
        width: 40px !important;
    }

    .sidebar__item-text {
        font-size: 10px;
    }
    
    .dropdown__header-text {
        font-size: 12px;
    }

    .tracker__icon {
        background-size: 16px;
    }

    .notify__icon {
        width: 16px;
        height: 16px;
    }

    .notify__title {
        font-size: 12px;
    }

    .header__dropdown {
        width: 12px;
        height: 12px;
    }

    .program__rule {
        font-size: 10px;
    }

    .deal-type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        font-size: 8px;
    }

    .ctm-option {
        font-size: 10px;
    }

    .header__logo {
        font-size: 12px;
    }

    .extend__option {
        width: 100px;
    }

    .extend__text {
        font-size: 8px;
    }

    .extend__icon {
        width: 16px;
        height: 16px;
    }

    .files-upload {
        width: 40px;
        height: 40px;
    }

    .files__requirements-text {
        font-size: 12px;
    }

    .files__input-text {
        font-size: 10px;
    }

    .files__item {
        padding: 10px;
    }

    .files__requirement-bottom {
        font-size: 12px;
    }

    .files__progressbar {
        height: 10px;
    }

    .paginator-row {
        gap: 5px;
    }

    .small-container {
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .popup-wrapper {
        max-height: 80vh;
    }

    .paginator-row .input-wrapper {
        display: none;
    }

    .btn {
        font-size: 10px;
    }

    .deals-header {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .deals-title, .deals-list {
        width: fit-content;
    }

    .top__item {
        text-wrap: nowrap;
        width: fit-content;
    }

    .contract-panel {
        width: 100vw;
    }

    .panel-ftr {
        flex-direction: column;
    }

    .panel-ftr a {
        width: 100%;
    }

    .dropdown__header {
        flex-direction: row-reverse;
    }

    .collaterals__warning-text {
        font-size: 12px;
        font-weight: 700;
    }

    .container-grid .input, .popup-row .input {
        width: 100%;
        margin-bottom: 0;
    }

    .input .input__entry, .input .input__combobox {
        /* width: 100% !important; */
    }

    .files-container {
        flex-direction: column;
        height: 100%;
    }

    .files__list {
        width: 100%;
        height: 50%;
    }

    .files__input {
        width: 100%;
        height: 50%;
    }

    .files__items {
        max-height: 100%;
    }

    .files__file {
        max-width: fit-content;
        width: fit-content;
    }

    .sz-xxl, .sz-xl {
        width: 100% !important;
    }

    .dropdown__header-text {
        font-size: 8px;
    }
    
    .dropdown-text {
        font-size: 8px;
    }

    .categories__item {
        font-size: 8px !important;
    }
    
    .pie-charts {
        flex-direction: column
    }

    .block-404 {
        width: 90%;
        padding: 10px;
    }

    .vehicle__name {
        margin-bottom: 0;
    }

    .info__block {
        text-align: center;
    }

    .box-segment {
        flex-direction: column;
    }

    .header__name[type]::after {
        width: 12px;
        height: 12px;
    }

    .nav__list {
        gap: 10px;
    }

    .header__nav {
        margin-left: 20px;
    }

    .header__logo {
        display: none;
    }

    .info-wrapper {
        overflow-x: scroll;
    }

    .info__box {
        min-width: 50vw;
    }

    .flx-row-responsive {
        flex-direction: column !important;
        align-items: flex-start;        
    }

    .text-ghost {
        height: 0;
    }

    .sz-fill .input, .sz-fill .input-wrap {
        width: 100%;
    }

    .sidebar-section {
        width: var(--sidebar-hidden-width) !important;
    }

    .sidebar__hide {
        display: none;    
    }

    .input-wrap input {
        width: 100% !important;
    }

    .header__notifications {
        width: 90vw;
    }
}