@font-face {
    font-family: 'Supreme'; /*a name to be used later*/
    src: url('./fonts/Supreme-Variable.ttf'),
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-color: #5382a8;
    --main-color-grayscale: #4b86b6;
    --button-color: #414756;
    --header-color: #ffffff;
    --sidebar-color: #ffffff;
    --remove-color: #b93434;
    --add-color: #1f9710;
    --deal-header-color: rgba(240, 240, 240, 0.25);
    --main-font: "Supreme";
    --support-width: 400px;
    --support-height: 470px;
    --header-height: 60px;

    --sidebar-hidden-width: 42px;

    --standart-input-width: 200px;
    --standart-input-height: 40px;

    --profile-option-width: 200px;
    --scrollbar-thumb-color: rgb(0, 0, 0, 0.5);
    --scrollbar-background-color: #d3d3d3;
    --border-blacky: rgb(30, 44, 56, 0.1);
}

body {
    font-family: var(--main-font), Arial, Helvetica, sans-serif;
    min-height: 100vh;
    /* background: linear-gradient(to right, rgba(9, 9, 9, 0.75), rgba(32, 32, 32, 1)); */
    overflow-x: hidden;
    overflow-y: scroll;
}

/* body::-webkit-scrollbar {
    width: 50px;
} */

input {
    font-family: var(--main-font), sans-serif;
}

.wrapper {
    min-height: 100vh;
    padding-top: calc(var(--header-height));
    opacity: 0;
    transition: opacity 0.25s ease;
    overflow: hidden;
}

.wrapper-visible {
    opacity: 1 !important;
}

.structure {
    opacity: 0;
    transition: opacity 0.1s ease;
}

.intro__logo {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 3px;
    color: var(--main-color)
}

.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    background-color: var(--header-color);
    height: var(--header-height);
    z-index: 2;
    border-bottom: 1px solid var(--border-blacky);
}

.header__nav {
    display: flex;
    align-items: center;
}

.header__logo {
    padding: 8px;
    font-size: 20px;
    font-weight: 500;
    color: var(--main-color);
    margin-left: 20px;
    margin-right: 40px;
    text-decoration: none;
    border-radius: 10px;
    display: flex;
    gap: 3px;
}

.header__account {
    display: flex;
    gap: 10px;
}

.header__bell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.25s ease;
    cursor: pointer;
}

.header__bell:hover .header__bell-icon {
    fill: var(--main-color);
}

.header__bell-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__bell-icon svg {
    width: 16px;
    height: 16px;
    transition: fill 0.25s ease;
    fill: inherit;
}

.header__notifications {
    position: fixed;
    width: 400px;
    height: 500px;
    background-color: #fff;
    border: 1px solid var(--border-blacky);
    filter: drop-shadow(0 4px 4px var(--border-blacky));
    border-radius: 0 0 10px 10px;
    z-index: 10;
    top: calc(var(--header-height) - 1px);
    opacity: 0;
    transition: opacity 0.25s ease;
    pointer-events: none;
    overflow: hidden;

    --notifications-header-height: 30px;
}

.header__notifications-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: var(--notifications-header-height);
    border-bottom: 1px solid var(--border-blacky);
    padding: 0 5px;
}

.header__notifications-remove {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.5;
    transition: opacity 0.25s ease;
    cursor: pointer;
}

.header__notifications-remove:hover {
    opacity: 1;
    text-decoration: underline;
}

.notifications-show {
    opacity: 1;
    pointer-events: all;
}

.new-notifications {
    fill: var(--main-color);
    filter: drop-shadow(0 0 5px var(--main-color))
}

.header__notifications-list {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    max-height: calc(100% - var(--notifications-header-height));
}

.header__notifications-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--scrollbar-background-color);
}

.header__notifications-list::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
}

.contracts-list::-webkit-scrollbar {
    width: 8px;
    background-color: var(--scrollbar-background-color);
    border-radius: 10px;
}

.contracts-list::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 10px;
}

.notification {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
}

.notification-odd {
    background-color: #f8f8f8;
}

.notification__body {
    display: flex;
    justify-content: space-between;
}

.notification__section {
    display: flex;
    gap: 5px;
}

.notification__type {
    min-width: 20px;
    min-height: 20px;
    background-image: url("../images/icons/notify-warn.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.notification__text {
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
}

.notification__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;
}

.notification__footer-time {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.75;
}

.notification__clear {
    background-image: url("../images/icons/remove-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.header__notifications-empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    opacity: 0.75;
    font-weight: 300;
    animation: appear 0.25s ease;
}

.logo-letter {
    transition: all 0.25s ease;
    line-height: 0;
}

.nav__list {
    display: flex;
    gap: 40px;
    height: 100%;
}

.nav__item {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0);
    opacity: 0;
    transition: background-color 0.25s ease;
    color: black;
}

.droppable-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.nav__list-droppable {
    z-index: 101;
    opacity: 0;
    transform: scale(0.95);
    position: absolute;
    top: calc(var(--header-height) - 1px);
    pointer-events: none;
    background-color: #fcfbfb;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid var(--border-blacky);
    border-radius: 8px;
    transition: all 0.25s ease;
}

.nav__list-droppable .nav__item {
    width: 100%;
}

.droppable-hover {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}

.nav__link {
    text-wrap: nowrap;
    position: relative;
    color: inherit;
    transition: color 0.25s ease, background-color 0.25s ease;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    border-radius: 10px;
    width: 100%;
    padding: 5px 10px;
}

.nav__link[href]:hover, .link-active {
    z-index: 100;
    color: var(--main-color-grayscale);
    fill: var(--main-color);
    background-color: rgb(83, 130, 168, 0.05);
}

.nav__link[for]:hover:after {
    opacity: 1;
}

.nav__link-icon svg {
    width: 20px;
    height: 20px;
    transition: fill 0.25s ease;
    fill: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav__link[for] {
    padding-right: 20px;
}

.nav__link[for]:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    opacity: 0.5;
    transform: translateY(-50%) rotate(90deg);
    width: 16px;
    height: 16px;
    background-image: url("../images/icons/caret-right.png");
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(1);
    transition: top 0.25s ease;
}

.nav__link-text {
    font-size: 14px;
}

.header__profile {
    float: right;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.25s ease;
    cursor: pointer;
    gap: 10px;
}

.header__names {
    display: flex;
    flex-direction: column;
}

.header__profile:hover, .arrow-rotate {
    color: var(--main-color-grayscale);
}

.arrow-rotate, .header__profile:hover {
    background-color: var(--button-color);
    color: white;
}

.arrow-rotate .profile__extend, .header__profile:hover .profile__extend {
    filter: invert(0);
}

.profile__company {
    color: inherit;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    transition: color 0.25s ease;
}

#company-name {
    font-size: 10px;
    opacity: 0.75;
}

.profile__extend {
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/vertical-arrow-profile.png");
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(1);
    transition: transform 0.25s ease, filter 0.25s ease;
    z-index: 3;
}

.arrow-rotate .profile__extend {
    transform: rotate(180deg);
}

.header__extend {
    background-color: var(--header-color);
    position: fixed;
    z-index: 3;
    border: 1px solid var(--border-blacky);
    border-radius: 4px;
    filter: drop-shadow(0px 4px 10px var(--border-blacky));
    opacity: 0;
    transition: opacity 0.25s ease;
}

.extend-active {
    opacity: 1;
}

.extend__option {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.75pt;
    padding: 10px;
    border-bottom: 1px solid var(--border-blacky);
    color: black;
    width: var(--profile-option-width);
    cursor: pointer;
    text-transform: uppercase;
    transition: color 0.25s ease, fill 0.25s ease;
    text-decoration: none;
}

.extend__option:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.extend__icon {
    width: 16px;
    height: 16px;
    transition: inherit;
}

.extend__icon svg {
    width: 100%;
    height: 100%;
    fill: inherit;
}

.extend__text {
    transition: padding-left 0.25s ease;
    line-height: 0;
}

.extend__option:hover {
    color: var(--main-color-grayscale);
}

.extend__option:hover .extend__icon {
    fill: var(--main-color);
}
    
.info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 150px;
    padding: 15px 10px 15px 10px;
    background-color: var(--deal-header-color);
    transition: transform 0.25s ease;
    border-bottom: 1px solid var(--border-blacky);
}

.info__box {
    position: relative;
    background-color: white;
    padding: 10px;
    height: 100%;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    overflow: visible;
}

.btn-close {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.info__text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.info__text .vehicle__name {
    margin-bottom: 0;
}

.deal-updated:after {
    --update-color: #ec8908;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--update-color);
    filter: drop-shadow(0 0 4px var(--update-color));
    border-radius: 50%;
    top: -4px;
    right: -4px;
    animation: update-bounce 1.5s ease infinite;
}

.deal__status {
    width: fit-content;
    background-color: rgba(199, 164, 112, 0.75);
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.status-good {
    background-color: rgba(35, 166, 23, 0.75)
}

.status-med {
    background-color: rgba(168, 159, 28, 0.75)
}

.status-bad {
    background-color: rgba(203, 21, 21, 0.75)
}

.vehicle__name {
    position: relative;
    font-size: 14px;
    color: var(--main-color);
    opacity: 0.75;
    margin-bottom: 5px;
    font-weight: bold;
}

.vehicle__name[category] {
    cursor: pointer;
}

.vehicle__name[category]:hover {
    text-decoration: underline;
}

.vehicle__spec {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8)
}

.box-segment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.info__block {
    display: flex;
    flex-direction: column;
}

.info__buttons {
    display: flex;
    gap: 10px;
}

.info__box-edit {
    width: 20px;
    height: 20px;
    background-image: url("../images/icons/popup-save.png");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: filter 0.25s ease;
}

.box-change { 
    background-image: url("../images/icons/add.png") !important;
}

.box-cobuyer { 
    background-image: url("../images/icons/cobuyer.png");
}

.box-separate {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.info__edit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    animation: appear 0.1s ease;
}

.contracts {
    padding: 20px;
    width: 100%;
}

.contracts__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contracts__search {
    padding-bottom: 20px;
}

.contracts__header {
    display: flex;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 5px;
    margin-bottom: 15px;
    gap: 20px;
}

.contract-item {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--border-blacky);
    padding-bottom: 5px;
    cursor: pointer;
}

.contract__item {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.contracts-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 50vh;
    padding-right: 10px;
}

.contracts-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0 20px 0;    
}

.info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.info__edit input[source-type=number] {
    text-align: left;
}

.info__edit .btn {
    align-self: end;
}

.main-section {
    opacity: 0;
    padding: 20px;
    width: 100%;
    display: flex;
    transition: opacity 0.25s ease;
    position: relative;
}

.collateral-section {
    width: 100%;
}

.warnings-section {
    display: flex;
    gap: 10px;
    overflow-x: auto;
}

.collaterals {
    padding: 10px;
    background-color: #FFF;
    border: 1px solid var(--border-blacky);
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.collaterals__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid var(--border-blacky);
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    width: 150px;
}

.collaterals__item:hover .collaterals__item-id {
    text-decoration: underline;
}

.collaterals__item-id {
    font-size: 14px;
    font-weight: 500;
}

.collaterals__item-status {
    opacity: 0.75;
}

.collaterals__warning {
    padding: 10px;
    background-color: rgb(244, 67, 54, 0.25);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.warning-second {
    background-color: rgb(255, 202, 40, 0.25);
}

.warning-second .collaterals__warning-text {
    color: rgb(185, 139, 2);
}

.warning-good {
    background-color: rgb(76, 175, 80, 0.25)
}

.warning-good .collaterals__warning-text {
    color: rgb(76, 175, 80)
}

.collaterals__warning-icon {
    background-image: url("../images/icons/icon-warning.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
}

.collaterals__warning-text {
    font-size: 14px;
    font-weight: 500;
    color: rgb(244, 67, 54);
}

.main-section__scrollable {
    position: relative;
    max-height: calc(100vh - 10px - var(--header-height));
}

.main-section-animation {
    animation: appear_slide 0.25s ease;
}

.main-section__wrapper {
    width: 100%;
    background-color: var(--deal-header-color);
    overflow-x: hidden;
}

.section__header {
    padding: 10px;
    background-color: #414756FA;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section__header-text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8pt;
    color: white;
    text-transform: uppercase;
}

.section__header-collapse {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-image: url("../images/icons/deal-dropdown.png");
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
}

.section__header:hover .section__header-collapse {
    opacity: 1;
}

.section__wrapper {
    display: flex;
    flex-direction: column;
}

.block-nogap {
    gap: 0 !important;
}

.container-wrapper {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0 0 10px 10px;
    background-color: #FFFFFF;
    border: 1px solid var(--border-blacky);
    overflow: hidden;
}

.sidebar-section {
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: var(--sidebar-color);
    opacity: 0;
    transition: opacity 0.5s ease;
    border-right: 1px solid var(--border-blacky);
}

.sidebar-transition {
    opacity: 1;
    transition: width 0.25s ease, opacity 0.25s ease;
}

.sidebar__list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sidebar__item {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 16px;
    color: black;
    padding: 20px 5px 20px 10px;
    border-bottom: 1px solid var(--border-blacky);
    transition: background-color 0.25s ease;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
}

.sidebar__item:hover {
    background-color: var(--button-color);
    color: white;
}

.sidebar__item:hover .sidebar__item-text {
    padding-left: 10px;
}

.sidebar__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    border-bottom: 1px solid var(--border-blacky);
    height: 40px;
}

.sidebar__hide {
    pointer-events: all !important;
    width: 24px !important;
    height: 24px !important;
    background-image: url("../images/icons/caret-left.png");
    cursor: pointer;
    transition: transform 0.25s ease;
}

.sidebar-hidden {
    width: var(--sidebar-hidden-width) !important;
}

.sidebar__item-text {
    white-space: nowrap;
    transition: padding 0.25s ease;
    font-size: 14px;
    font-weight: 500;
}

.main-section__top {
    display: flex;
    background-color: rgba(200, 200, 200, 0.1);
    border-bottom: 1px solid var(--border-blacky);
    border-right: none;
    border-left: none;
    min-height: 40px;
    overflow-x: auto;
}

.top__item {
    text-transform: uppercase;
    padding: 10px;
    transition: background-color 0.25s ease, border-bottom 0.25s ease;
    border-bottom: 1px solid rgba(200, 200, 200, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.top__item-text {
    color: inherit;
}

.top__item-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.top__item:hover, .item-selected {
    background-color: white;
}

.calc-wrapper {
    color: black;
    width: 30%;
}

.horizontal-line {
    background-color: var(--border-blacky);
    width: 100%;
    height: 1px;
    margin: 15px 0;
}

.calc__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.calc__header-text {
    font-size: 20px;
}

.calc__header-buttons {
    display: flex;
    gap: 10px;
}

.btn {
    color: white;
    background-color: var(--main-color);
    filter: drop-shadow(0 0 3px var(--border-blacky));
    transition: background-color 0.25s, color 0.25s, filter 0.25s, border 0.25s ease, box-shadow 0.25s ease;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5pt;
    line-height: normal;
    text-align: center;
    padding: 10px 15px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 10px;
}

.btn-nobackground .img-default, .btn-add-transparent .img-default {
    filter: invert(0.75);
}

.btn-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
}

.btn-icon {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-text {
    font-size: inherit;
}

.btn-shadow {
    justify-content: center;
}

.btn-nobackground {
    color: var(--button-color);
    background-color: rgba(255, 255, 255, 0);
    filter: none;
    border: 1px solid var(--border-blacky);
}

.btn-remove-transparent {
    background-color: #fff !important;
    color: var(--remove-color);
    filter: none;
}

.btn-remove-transparent:hover {
    background-color: rgb(185, 52, 52, 0.2) !important;
}

.btn-remove {
    background-color: var(--remove-color);
}

.btn-black {
    background-color: #4e4e4e;
}

.btn-add {
    background-color: var(--add-color);
}

.btn-add-transparent {
    background: none;
    color: var(--add-color);
    filter: none;
}

.btn-add-transparent:hover {
    background-color: rgb(31, 151, 16, 0.2) !important;
}

.btn:hover {
    background-color: #3b5d79;
}

.btn-remove:hover {
    background-color: #a80c0c;
}

.btn-add:hover {
    background-color: #1b740f;
}

.btn-black:hover {
    background-color: #2e2e2e;
}

.btn-nobackground:hover {
    background-color: var(--border-blacky) !important;
}

.btn[disabled] {
    background-color: #acacac;
    color: rgba(0, 0, 0, 0.5);
}

.btn[disabled]:hover {
    background-color: auto;
}

.btn-nobackground[disabled] {
    background-color: rgb(0, 0, 0, 0.1);
}

.calc-form {
    margin-top: 20px;
}

.input-adder::before {
    content: "+";
    position: absolute;
    right: 200px;
    font-size: 24px;
    text-align: center;
    color: rgba(150, 150, 150, 0.75)
}

.input-minus::before {
    content: "-";
    position: absolute;
    right: 200px;
    font-size: 30px;
    color: rgba(150, 150, 150, 0.75)
}

.btn-wrapper {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__input-search, .popup__list-search {
    position: absolute;
    background-image: url("../images/icons/search.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 32px;
    height: 100%;
}

.popup__list-search {
    background-size: 18px;
    right: 0;
    cursor: pointer;
}

.popup__input-search {
    border-radius: 3px 0 0 3px;
    background-color: var(--border-blacky);
    background-size: 16px;
    left: 0;
    margin: 1px;
    height: calc(100% - 2px);
}

#list-search:focus + .popup__list-search {
    background-size: 22px;
}

.ui-resizable {
	position: relative
}

.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	-ms-touch-action: none;
	touch-action: none
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
	display: none
}

.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0
}

.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0
}

.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%
}

.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%
}

.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px
}

.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px
}

.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px
}

.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px
}

.ui-sortable-handle {
	-ms-touch-action: none;
	touch-action: none
}

.ui-datepicker {
    width: 300px;
    height: auto;
    margin: 5px auto 0;
    font: 9pt var(--main-font);
    background-color: white;
    box-shadow: 0 4px 10px 0px rgba(0, 0, 0, .5);
    border-radius: 4px;
    padding: 0;
    border: none !important;
    border-radius: 10px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.ui-datepicker-selector {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.ui-datepicker-stepper {
    width: 50%;
    padding: 5px;
    font-size: 14px;
    background-color: white;
    border-radius: 4px;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-evenly;
}

.stepper__dir {
    width: 16px;
    height: 16px;
    background-image: url("../images/icons/caret-left.png");
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(1);
    cursor: pointer;
}

.stepper__dir[next] {
    background-image: url("../images/icons/caret-right.png");
}

.ui-datepicker-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #fff;
    padding: 10px;
}

.ui-datepicker-option {
    font-size: 12px;
    padding: 10px;
    width: 80px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    background-color: #fff;
    border-radius: 10px;
    transition: filter 0.2s ease;
    margin-bottom: 5px;
}

.ui-disabled {
    opacity: 0.5;
    background-color: var(--border-blacky);
    pointer-events: none;
}

.ui-datepicker-option:hover, .ui-active {
    color: var(--main-color);
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
	padding: .2em 0;
    background: none;
    background-color: var(--main-color);
    border-radius: 4px 4px 0 0;
    color: white;
    font-weight: bold;
    line-height: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui-datepicker a {
    text-decoration: none;
}

.ui-datepicker table {
    width: 100%;
}

.ui-datepicker-title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.ui-datepicker-prev, .ui-datepicker-next {
    position: absolute;
	top: 2px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    background-image: url('../images/icons/caret-right.png') !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    overflow: hidden;
    top: 50% !important;
    transform: translateY(-50%);
}

.ui-datepicker-prev {
    left: 10px !important;
    background-image: url('../images/icons/caret-left.png') !important;
}

.ui-datepicker-next {
    right: 10px !important;
}

.ui-datepicker-prev:hover, .ui-datepicker-next:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
}

.ui-datepicker-year, .ui-datepicker-month {
    color: white;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
    background-color: var(--main-color);
    transition: filter 0.25s ease;
}

.ui-datepicker-year:hover, .ui-datepicker-month:hover {
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.5));
}

.ui-datepicker-next {
    float: right;
}

.ui-icon {
    display: none !important;
}

.ui-datepicker thead {
    color: black;
    background-color: rgba(240, 240, 240);
}

.ui-datepicker tr {
    background-color: white;
}

.ui-datepicker th {
    text-transform: uppercase;
    font-size: 8pt;
    color: rgba(0, 0, 0, 0.75);
    text-shadow: 1px 0px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=0);
}

.ui-datepicker tbody td {
    padding: 2px;
}

.ui-datepicker td span, .ui-datepicker td a {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #000000;
    text-shadow: 1px 1px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=1);
}

.ui-datepicker-calendar {
    padding-top: 5px;
}

.ui-datepicker-calendar .ui-state-default {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui-datepicker tbody tr {
    border-bottom: none;
}

.ui-datepicker-unselectable .ui-state-default {
    background: #f4f4f4;
    color: black;
}

.ui-datepicker-calendar .ui-state-hover {
    background: #f7f7f7;
}

.ui-datepicker-calendar .ui-state-active {
    text-shadow: none;
    color: white;
    background: var(--button-color);
}

.ui-state-highlight {
    background-color: red;
}

.ui-state-disabled {
    opacity: 0.5;
}

.wrapper__combo {
    font-size: 12px;
    border-radius: 4px;
    min-height: var(--standart-input-height);
}

.wrapper__combo:focus {
    outline: none;
}

.wrapper__options {
    width: 4px;
    height: 4px;
    display: inline-block;
    background-color: black;
    border-radius: 50%;
    border: none;
}

.wrapper__options::before {
    content: "";
    position: absolute;
    background-color: black;
    border-radius: 50%;
    top: 0px;
    width: 4px;
    height: 4px;
}

.wrapper__options::after {
    content: "";
    position: absolute;
    background-color: black;
    border-radius: 50%;
    bottom: 0px;
    width: 4px;
    height: 4px;
}

.input-block {
    display: flex;
}

#first_payment {
    padding-right: 80px;
}

.popup-wrapper {
    color: black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    border-radius: 0 10px 10px 0;
    border: 1px solid var(--border-blacky);
    padding: 20px;
    column-gap: 5px;
    row-gap: 20px;
    overflow: auto;
}

.popup-close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    font-weight: bold;
    text-transform: lowercase;
    width: 16px;
    height: 16px;
    background-image: url("../images/icons/close.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.popup__section-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    row-gap: 20px;
}

.popup-window, .popup-window-2 {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: all 0.1s ease-in;
    pointer-events: none;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-window-2 {
    z-index: 4
}

.window-huge {
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0 !important;
}

.window-thin {
    width: 20vw !important;
    height: 40vh !important;
}

.window-auto {
    width: auto !important;
    height: auto !important;
    max-height: 100vh;
}

.window-active {
    pointer-events: all;
    opacity: 1;
}

.window-active .window__panel, .window-active .window__panel-2 {
    animation: window_appear 0.25s ease;
}

.window__panel, .window__panel-2 {
    border-radius: 10px;
    border: 1px solid var(--border-blacky);
    filter: drop-shadow(0 0 5px var(--border-blacky));
    display: flex;
    flex-direction: column;
    width: 35vw;
    height: 60vh;
    background-color: white;
    overflow: hidden;
}

.panel__header, .panel__header-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
    padding: 10px 15px 10px 15px;
    background-color: rgb(250, 250, 250);
}

.panel__title {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}

.panel__close, .panel__close-2, .viewer-close {
    --close-btn-size: 28px;

    width: var(--close-btn-size);
    height: var(--close-btn-size);
    background-image: url("../images/icons/close.png");
    background-repeat: no-repeat;
    background-size: calc(var(--close-btn-size) * 0.75);
    background-position: center;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.25s ease;
    background-color: rgba(50, 50, 50, 0);
}

.panel__close:hover, .panel__close-2:hover, .viewer-close:hover {
    background-color: rgba(50, 50, 50, 0.1);
}

.list__wrapper, .list__wrapper-2 {
    position: relative;
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.panel__list, .panel__list-2 {
    display: flex;
    width: 100%;
    height: 100%;
}

.user-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.list-column {
    flex-direction: column;
}

.popup-outer, .options-outer, .calendar-outer {
    position: absolute;
    top: 0;
    left: 0;
}

.popup-element {
    opacity: 0;
    transition: opacity 0.1s ease-in;
    pointer-events: none;
}

.popup-column {
    display: flex;
    flex-direction: column;
}

.selection__wrapper {
    position: relative;
    display: flex;
}

.popup__header {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(2, 137, 247);
    margin: 10px 0 10px 0;
    text-transform: uppercase;
}

.popup__item-remove {
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/remove-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: background-color 0.25s ease;
    opacity: 0.75;
}

.popup__list {
    display: flex;
    flex-direction: column;
}

.popup__input-row .popup-text {
    margin-bottom: 0;
}

.support__label {
    text-align: center;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background-color: #fff;
    padding: 5px;
    border: 1px solid var(--border-blacky);
    border-radius: 4px;
}

.text-error {
    color: rgb(255, 82, 82);
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
}

.popup-show {
    opacity: 1;
    pointer-events: all;
}

.advanced-wrapper {
    display: flex;
    width: calc(40% - 40px);
    border-left: 1px solid var(--border-blacky);
    border-right: 1px solid var(--border-blacky);
    margin-left: 20px;
    padding: 20px;
}

.advanced-wrapper .popup-column {
    width: 100%;
}

.summary-wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 20px;
}

.summary-wrapper .input-wrapper {
    padding: 10px 0;
}

.deals-container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

.deals-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
}

.deals__side {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.deals__row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cms__table {
    overflow-x: auto;
}

.deals-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    background-color: #f0efef;
    border: 1px solid var(--border-blacky);
    z-index: 1;
}

.header__dropdown {
    background-image: url("../images/icons/deal-dropdown.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    transition: transform 0.25s ease, filter 0.25s ease;
    filter: invert(1);
}

.deals-expanded .header__dropdown {
    transform: rotate(180deg);
    filter: invert(0)
}

.header__name {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.header-bold {
    font-weight: 600;
    text-transform: none;
}

.deals-title .header__name {
    opacity: 0.75;
    font-weight: bold;
    font-size: 12px;
}

.header__link {
    color: inherit;
    text-decoration: none;
}

.header__link:hover {
    text-decoration: underline;
}

.header__tracker {
    background-color: var(--border-blacky);
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color 0.25s ease;
}

.deals-expanded .header__tracker {
    background-color: white;
}

.tracker__icon {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    border-radius: 20px;
    transition: background-color 0.25s ease;
    opacity: 0.75;
}

.tracker__icon:hover {
    background-color: rgba(0, 0, 0, 0.1)
}

.tracker-bank {
    background-image: url("../images/icons/trackers/bankReports.svg");
}

.tracker-documents {
    background-image: url("../images/icons/trackers/stipulations.svg");
}

.color-gray {
    color: #838383;
    transition: color 0.25s ease;
}

.header-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.deals-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: height-grow 0.25s ease;
    /* overflow-y: auto; */
}

.list-scroll {
    overflow-y: auto;
}

.deals__deal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(250, 250, 250);
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    transition: border-color 0.1s ease, background-color 0.25s ease, color 0.25s ease;
    cursor: pointer;
    height: 80px;
    gap: 10px;
}

.small-item {
    height: 50px;
}

.deals-expanded {
    background-color: #414756 !important;
    color: white !important;
    border-bottom: 1px solid var(--border-blacky);
}

.deal-odd {
    background-color: rgb(240, 240, 240);
}

.deal-disabled {
    height: 60px;
    background-color: rgb(236, 211, 211);
}

.deal-disabled .header__tracker {
    opacity: 0;
    pointer-events: none;
}

.deal-odd .deal__status {
    color: rgb(240, 240, 240) !important;
}

.deals__deal:hover {
    background-color: #7e7e7e54;
}

.deals-expanded:hover {
    background-color: #414756;
}

.deals__info {
    background-color: #fcfcfc;
    overflow: hidden;
}

.header__action-btn {
    background-color: var(--main-color);
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    color: white;
}

.dropdown {
    padding: 40px;
    animation: dropdown-appear 0.5s ease;
}

.dropdown__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.dropdown__header-text .color-gray {
    text-transform: none;
}

.deals-expanded .color-gray {
    color: white;
}

.dropdown__header-text {
    font-size: 16px;
}

.dropdown-wrap {
    display: flex;
    gap: 20px;
}

.dropdown__container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dropdown__row {
    display: flex;
}

.dropdown__side {
    display: flex;
    width: 35%;
    padding-left: 10px;
    gap: 5px;
}

.dropdown__side:first-child {
    padding-left: 0;
    width: 65%;
    padding-right: 10px;
    border-right: 1px solid var(--border-blacky);
}

.dropdown__panel {
    background-color: #FFFFFF;
    border: 1px solid var(--border-blacky);
    padding: 20px;
    width: 10vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
}

.panel-header {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dropdown-text {
    font-size: 14px;
}

.db-bold {
    text-transform: uppercase;
    font-weight: 800;
}

.db-big {
    font-size: 16px;
}

.dropdown-action {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.25s ease;
}

.dropdown-action:hover {
    color: #19578a;
}

.db-blue {
    color: var(--main-color)
}

.db-red {
    color: #f73b02
}

.dropdown-img {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--main-color);
}

.dropdown-img.db-red {
    fill: #f73b02;
}

.dropdown-img svg {
    width: 24px;
    height: 24px;
    fill: inherit;
}

.img-grey {
    filter: grayscale(1);
    opacity: 0.5;
}

.wrapper__login {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    --login-width: 400px;
}

.login__return-wrapper {
    width: var(--login-width);
    transition: opacity 0.25s ease;
}

.login__return {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.25s ease;
    text-decoration: none;
}

.login__return:hover {
    opacity: 1;
}

.login__return-icon {
    background-image: url('../images/icons/icon-return-2.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.login__return-text {
    text-transform: uppercase;
    line-height: 0;
    color: black;
}

.login-container {
    border: 1px solid var(--border-blacky);
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: var(--login-width);
    animation: login-appear 0.5s ease;
    overflow: hidden;
    background-color: #FFF;
    border-radius: 10px;
}

.login-flashout {
    animation: login-disappear 0.5s ease forwards 0.5s;
}

.login__wrapper {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: scale(0);
    opacity: 0;
    animation: wrapper-appear 0.5s ease 0.25s forwards;
}

.login__or {
    margin-top: 10px;
    padding: 20px 0 20px 0;
    text-align: center;
    position: relative;
    font-size: 12px;
}

.login__or:after, .login__or:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(50% - 40px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.login__or:before {
    left: 0;
}

.login__or:after {
    right: 0;
}

.login__link {
    opacity: 0.75;
    text-decoration: none;
    color: black;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    transition: color 0.25s ease, background-color 0.25s ease;
    cursor: pointer;
    margin-top: 10px;
}

.login__link:hover {
    color: var(--main-color);
}

.login__title {
    padding: 20px 40px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.login__title-text {
    opacity: 0.9;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.login__title-img {
    width: 32px;
    height: 32px;
    background-image: url("../images/icons/login.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.login__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 5px;
}

.login__label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.5;
}

.login__input {
    border: none;
    border: 1px solid var(--border-blacky);
    transition: filter 0.1s ease;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
}

.login__input:focus {
    outline: none;
}

.login__button {
    outline: none;
    align-self: center;
    margin-top: 0px;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    border: none;
    display: flex;
    gap: 20px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    border-radius: 4px;
    border: 1px solid var(--border-blacky);
    transition: background-color 0.25s linear;
    background-color: #f0f0f0;
    background-image: url("../images/icons/login-success.png");
    background-size: 0px;
    background-repeat: no-repeat;
    background-position: center;
}

.login__button:hover {
    background-color: var(--main-color);
    color: white;
}

.login__button-text {
    font-family: var(--main-font);
    font-size: 14px;
    transition: opacity 0.1s ease;
}

.login-success {
    background-color: green !important;
    color: rgba(255, 255, 255, 0);
    animation: success-appear 0.25s linear forwards;
}

.login-success .login__button-img {
    opacity: 0;
}

.login-success .login__button-text {
    opacity: 0;
}

.login-error {
    border-radius: 10px;
    visibility: hidden;
    transition: opacity 0.25s ease;
    position: absolute;
    padding: 5px;
    margin-bottom: 10px;
    opacity: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.error-text {
    font-size: 12px;
    text-align: center;
    color: #c90f0f;
}

.error-icon {
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/login-error.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.error-show {
    position: static;
    visibility: visible;
    opacity: 1;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid rgba(255, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__oauth {
    padding: 10px;
    background-color: #fafafa;
    border: 1px solid var(--border-blacky);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.25s ease;

    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    text-decoration: none;
}

.login__oauth:hover {
    background-color: #eeeeee;
}

.oauth__icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.oauth__name {
    color: black;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
}

.paginator-row {
    background-color: #fcfcfc;
    position: relative;
    --paginator-color: var(--main-color);
    width: 100%;
    height: 100px;
    padding: 20px;
    border-top: 1px solid var(--border-blacky);
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginator__item {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.25s ease;
    color: var(--paginator-color);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.25s ease;
}

.paginator__item:hover, .paginator-active {
    text-decoration: underline;
}

.paginator__link {
    padding: 5px;
    font-weight: 400;
    color: inherit;
    text-decoration: none;
}

.selection__item {
    position: relative;
    background-color: var(--border-blacky);
    padding: 10px;
    cursor: pointer;
    height: 50px;
    border-left: 4px solid var(--border-blacky);
    display: grid;
    transition: all 0.25s ease;
    margin-bottom: 5px;
}

.selection__item a {
    display: grid;
    text-decoration: none;
    color: black;
}

.selection__item:hover {
    border-left: 4px solid var(--main-color);
}

.selection-text {
    font-size: 14px;
    text-align: center;
    align-self: center;
}

.selection-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    cursor: pointer;
    transition: background-color 0.25s ease;
}

.selection-icon:hover {
    background-color: rgb(0, 0, 0, 0.15)
}

.selection-remove {
    background-image: url("../images/icons/remove-2.png");
}

.selection-pdf {
    background-image: url("../images/icons/sidebar-reports-black.png");
    left: 0;
}

.header__name[type]::after, .list__text[type]::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/vertical-arrow-up.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: opacity 0.1s linear;
}

.sort-column::after {
    opacity: 1 !important;
}

.desc::after {
    background-image: url("../images/icons/vertical-arrow-down.png") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* .loader {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    position: relative;
    border: 10px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
  
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}  */

.loader-overlay {
    background-image: url("../images/loader/loader-2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease, backdrop-filter 0.2s ease;
    backdrop-filter: blur(0.5px);
    justify-content: center;
    align-items: center;
    pointer-events: all;
    z-index: 1001;
    gap: 10px;
}

.loader-bg {
    backdrop-filter: blur(10px);
}

#etc-popup {
    z-index: 6
}

.load-small {
    backdrop-filter: blur(10px) grayscale(1);
    background-size: 48px;
    border-radius: inherit;
}

.notify {
    position: fixed;
    right: 30px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #FFFFFF;
    border: 1px solid var(--border-blacky);
    filter: drop-shadow(0 4px 20px var(--border-blacky));
    animation: notify 0.5s cubic-bezier(0,.45,.34,.99);
    border-radius: 10px;
    z-index: 9999;
    color: black;
    backdrop-filter: blur(2px);
    font-weight: bold;
}

.notify-1 {
    --notify-color: rgba(83, 130, 168, 0.25);
    border-color: var(--notify-color);
}

.notify-2 {
    --notify-color: rgb(180, 23, 23, 0.25);
    border-color: var(--notify-color);
}

.notify-3 {
    --notify-color: rgba(23, 180, 36, 0.25);
    border-color: var(--notify-color);
}

.notify__header {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px;
    overflow: hidden;
}

.notify__title {
    font-size: 18px;
}

.notify__body {
    font-size: 12px;
    font-weight: bold;
    animation: notify_text_appear 0.25s ease;
}

.notify__icon {
    background-image: url("../images/icons/notify-warn.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.notify-icon-2 {
    background-image: url("../images/icons/login-error.png");
}

.notify-icon-3 {
    background-image: url("../images/icons/file-approved.png");
}

.files-container {
    display: flex;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.files__list {
    display: flex;
    flex-direction: column;
    width: 35%;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.files__requirements {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.files__requirements-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.files__progressbar {
    position: relative;
    background-color: #f8f8f8;
    height: 20px;
    width: 100%;
    border-radius: 4px;
}

.progressbar {
    width: 0%;
    transition: width 0.5s ease;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 4px;
}

.files__requirement-bottom {
    font-size: 16px;
    color: var(--main-color);
    text-align: center;
}

.files__items {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 70vh;
}

.files__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    transition: background-color 0.25s ease;
}

.files__item:first-child {
    border-radius: 10px 10px 0 0;
}

.files__item:last-child {
    border-radius: 0 0 10px 10px;
}

.files-icon {
    background-image: url("../images/icons/files-item.png");
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-click {
    opacity: 0;
    transition: opacity 0.25s ease;
    background-image: url("../images/icons/deal-dropdown.png");
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
    filter: invert(1)
}

.files__item:hover, .file-selected {
    background-color: #ede8e8;
}

.files__item:hover .icon-click, .file-selected .icon-click {
    opacity: 1;
}

.files-block {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 10px;
    flex: 1;
    text-transform: uppercase;
}

.files-title {
    font-size: 16px;
    font-weight: bold;
}

.files-text {
    font-size: 12px;
}

.files-required {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 5px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: bold;
}

.status-approved {
    color: green;
    border: 1px solid rgba(0, 150, 0, 0.2)
}

.status-denied {
    color: red;
    border: 1px solid rgba(255, 0, 0, 0.2)
}

.files__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 65%;
    background-color: white;
}

.files__input-text {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.75;
    text-transform: uppercase;
}

.files__top {
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: calc(100% - 10px);
    max-height: 20vh;
    overflow-y: auto;
}

.files__border {
    border-bottom: 1px solid var(--border-blacky);
    animation: extend_appear_y 0.25s ease;
}

.files__file {
    --width: 12vw;
    width: var(--width);
    max-width: var(--width);
    cursor: pointer;
    transition: background-color 0.25s ease;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--border-blacky);
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-approved, .file-denied {
    position: relative;
    border-color: rgba(49, 119, 49, 0.35);
}

.file-denied {
    border-color: rgba(255, 0, 0, 0.35);
}

.file-approved::after, .file-denied::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    background-image: url("../images/icons/file-approved.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.file-denied::after {
    background-image: url("../images/icons/login-error.png") ;
}

.file__name, .file__desc {
    font-size: 12px;
    text-align: center;
}

.file__name {
    text-wrap: nowrap;
    overflow: hidden;
    max-width: 300px;
    padding: 0 10px;
}

.file__desc {
    color: rgb(0, 0, 0, 0.5);
}

.files__file:hover {
    background-color: var(--border-blacky);
}

.files__bottom {
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
}

#deal-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.files-link {
    text-decoration: none;
    color: white;
}

.files-upload {
    background-image: url("../images/icons/upload.svg");
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-size: contain;
}

.files__viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 201;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;
}

.denied-block {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgb(158, 16, 16);
    padding: 5px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.denied-block {
    font-size: 16px;
}

.viewer-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.viewer-close {
    filter: invert(1);
    margin-top: 10px;
    margin-right: 20px;
    border-radius: 10px;
}

.viewer-close:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.files-view {
    opacity: 1;
    pointer-events: all;
}

.viewer-canvas {
    width: 95%;
    height: 95%;
    color: white;
    font-size: 16px;
}

.canvas-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas-container {
    overflow-x: hidden;
    animation: width-grow 0.25s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
}

.container__align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.valuation__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.valuation__body-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: 0.25s ease opacity;
    overflow-x: auto;

    --cell-size: 150px;
}

.valuation-item .wrapper__input {
    width: 90%;
}

.valuation-row {
    display: grid;
    grid-template-columns: repeat(6, var(--cell-size));
}

.valuation-item {
    min-width: var(--cell-size);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.valuation-category {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.valuation-category:hover, .valuation-category.active {
    background-color: rgb(0, 0, 0, 0.1);
}

.valuation-category[type=jdpower] {
    background-image: url("../images/icons/valuations/jdpower.webp");
}

.valuation-category[type=kbb] {
    background-image: url("../images/icons/valuations/kbb.webp");
}

.savewarning {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 200%) scale(1.2);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    filter: drop-shadow(0 -4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px 10px 0 0;
    background-color: var(--button-color);
    opacity: 0;
    transition: transform 0.25s ease, opacity 0.5s ease;
    backface-visibility: hidden;
}

.savewarning__icon {
    background-image: url("../images/icons/icon-warning-white.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
}

.savewarning__text {
    color: rgb(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-wrap: nowrap;
}

.msg-show {
    transform: translate(-50%, 0%) scale(1);
    opacity: 1;
}

.confirm-container {
    display: flex;
    flex-direction: column;
    width: 25vw;
    padding: 20px;
}

.confirm__text {
    padding-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
}

.confirm__box {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding-top: 10px;
}

.confirm__box:last-child {
    border-top: 1px solid var(--border-blacky);
}

.confirm__box .popup__input {
    width: 100%;
}

.deal-comments {
    width: 30vw;
    background-color: #FFFFFF;
    padding: 10px;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    gap: 10px;
}

.deal-comments__empty {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.75;
    text-align: center;
}

.deal-comments__list {
    padding: 15px;
    gap: 20px;
    overflow-y: auto;
    max-height: 60vh;
}

.deal__comments-body {
    padding: 10px;
    border-radius: 10px;
}

.deal-comments__footer {
    display: flex;
    justify-content: flex-end;
}

.comment {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.comment__header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.comment__user {
    text-transform: uppercase;
}

.comment__date {
    font-size: 12px;
    opacity: 0.75;
}

.comment__text {
    background-color: #FFFFFF;
    word-wrap: break-word;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--border-blacky);
}

.my-comment {
    border: 1px solid var(--main-color);
}

.banner {
    position: fixed;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 40px;
    border: 1px solid var(--border-blacky);
    background-color: #f9fafc;
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.2));
    bottom: 0;
}

.banner__title {
    font-size: 24px;
    font-weight: 700;
}

.banner__message {
    font-size: 16px;
    font-weight: 300;
    opacity: 0.75;
    margin-bottom: 10px;
}

.banner__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/close.png");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.contract-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contract-panel {
    background-color: white;
    border-radius: 10px;
    width: 25vw;
    animation: window_appear 0.25s ease;
}

.panel-huge {
    width: 100vw;
    height: 100vh;
}

.panel-hdr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
}

.hdr-title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.hdr-alter {
    filter: none;
    margin: 0;
}

.hdr-alter:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.panel-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    height: calc(100% - 120px);
    font-size: 14px;
}

.panel-file {
    border: 1px dashed rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
    transition: background-color 0.25s ease;
}

.panel-file:hover {
    background-color: var(--border-blacky);
}

.file-hint {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75)
}

.panel-iframe {
    width: 100%;
    height: 100%;
}

.panel-ftr {
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.panel-ftr a {
    text-decoration: none;
}

.servicing-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.servicing-content {
    display: flex;
    gap: 20px;
    width: 100%;
}

.servicing-buttons {
    display: flex;
    gap: 10px;
}

.servicing-section {
    flex-grow: 1;
}

.servicing-block {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
}

.servicing-dashboard, .servicing-block {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
}

.servicing-dashboard {
    display: flex;
    gap: 15px;
    padding: 10px;
}

.servicing-stats {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.servicing-stat {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stat-title {
    text-transform: uppercase;
    color: rgb(0, 0, 0, 0.5);
    font-size: 14px;
}

.stat-value {
    font-size: 12px;
}

.pie-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.pie-chart--wrapper {
    width: 300px;
    text-align: center;
    margin-right: 50px;
}

.pie-chart--wrapper h4 {
    margin-bottom: 20px;
}

.pie-chart__pie, .pie-chart__legend {
    display: inline-block;
    vertical-align: top;
}

.pie-chart__pie {
    position: relative;
    height: 200px;
    width: 200px;
    transform: scale(0.75);
}

.pie-chart__pie::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 50%;
    top: 50%;
    animation: present-pie 0.5s ease;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pie-chart__pie::after {
    content: "";
    display: block;
    width: 120px;
    height: 2px;
    border-radius: 50%;
}

.slice {
    position: absolute;
    width: 200px;
    height: 200px;
    clip: rect(0px, 200px, 200px, 100px);
    animation: bake-pie 0.5s ease;
}

.slice span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
}

.pie-chart__legend {
    display: block;
    list-style-type: none;
    padding: 0;
    background: #FFF;
    padding: 0.75em 0.75em 0.05em;
    font-size: 13px;
    text-align: left;
    width: 100%;
}

.pie-chart__legend li {
    height: 1.25em;
    margin-bottom: 0.7em;
    padding-left: 0.5em;
    border-left: 1.25em solid black;
}

.pie-chart__legend em {
    font-style: normal
}

.pie-chart__legend span {
    float: right
}

.pie-charts {
    display: flex;
    flex-direction: row;
}

.servicing-title {
    font-size: 20px;
}

.servicing-payment {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.payment-header {
    display: flex;
    justify-content: space-between;
    background-color: #DEDEDE;
    padding: 5px;
    margin-bottom: 5px;
}

.payment-body {
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 10px;
    overflow: hidden;
}

.payment-body, .payment-header {
    padding-right: 15px;
}

.payment-line {
    background-color: rgba(0, 0, 0, 0.15);
    height: 1px;
}

.body-hidden {
    padding: 0;
    opacity: 0;
}

.value-hidden {
    display: none !important;
}

.body-label {
    font-size: 14px;
}

.payment-value {
    display: flex;
    justify-content: space-between;
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
}

.payments-header {
    text-align: center;
    display: flex;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
}

.payments-header span {
    text-transform: uppercase;
}

.payments-list {
    display: flex;
    flex-direction: column-reverse;
}

.payments-item {
    text-align: center;
    background-color: #FCFCFC;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--main-color);
    border-bottom: 1px solid var(--border-blacky);
    cursor: pointer;
    transition: border-bottom 0.25s ease;
}

.payment-values {
    width: fit-content;
    display: flex;
}

.payment-options {
    display: flex;
    padding-right: 5px;
}

.payment-option {
    width: 16px;
    height: 16px;
    background-image: url("../images/icons/vehicle-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(1)
}

.payment-reversed .payment-option {
    filter: none;
}

.option-reverse {
    background-image: url("../images/icons/sidebar-return.png");
}

.payments-item:hover {
    border-bottom: 1px solid var(--main-color);
}

.payments-item span {
    font-size: 12px;
}

.item-odd {
    background-color: #F5F3F3;
}

.payment-reversed {
    color: white;
    background-color: rgba(228, 22, 22, 0.5)
}

.payment-real {
    background-color: var(--button-color);
    color: white;
}

.change-container {
    background-color: white;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirm-wrap {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    gap: 20px;
    animation: hover-appear 0.25s ease;
}

.vehicle-item {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #e9e9e9;
    gap: 25px;
    transition: border-bottom 0.25s ease, background-color 0.25s ease;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.vehicle-item:hover {
    border-bottom: 1px solid var(--main-color);
    background-color: #cecece;
}

.vehicle-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.vehicle-title {
    font-size: 14px;
}

.vehicle-body {
    font-size: 12px;
}

.vehicle-icon {
    width: 48px;
    height: 48px;
    background-image: url("../images/icons/vehicle-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.popup__header-wrapper {
    display: flex;
    gap: 20px;
}

.popup-selection {
    width: fit-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fdfdfd;
    border: 1px solid var(--border-blacky);
    border-radius: 8px;
    animation: selection-slide 0.25s ease;
    overflow: hidden;
    padding: 5px 0;
    z-index: 3;
}

.selection-discard {
    animation: selection-remove 0.25s ease;
}

.popup-btn {
    border-bottom: 1px solid var(--border-blacky);
    transition: background-color 0.25s ease;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.popup-btn:last-child {
    border-bottom: none;
}

.popup-btn:hover {
    background-color: #f0f0f0;
}

.popup-msg {
    font-size: 12px;
    font-weight: 700;
    opacity: 0.75;
}

.popup-icon {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(1);
}

.log-payment {
    width: 100%;
    padding: 10px;
}

.combo-custom {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    filter: invert(1);
    cursor: pointer;
    width: 18px;
    height: 18px;
    background-image: url("../images/icons/vertical-arrow-profile.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.combo-wrapper {
    position: relative;
    width: 100%;
}

.combo-wrapper input {
    width: 100% !important;
}

.hover-message {
    --hint-color: rgba(255, 255, 255);

    position: absolute;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    background-color: var(--hint-color);
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    animation: hover-appear 0.25s ease;
    pointer-events: none;
    z-index: 100;
    filter: drop-shadow(0 4px 20px var(--border-blacky));
    
    --triangle-size: 5px;
    --triangle-offset: 0px;
}

.hover-small {
    text-wrap: pretty;
    max-width: 25vw;
}

.hover-message:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -5px;
    left: calc(50% + var(--triangle-offset));
    transform: translateX(-50%);

    border-left: var(--triangle-size) solid transparent;
    border-right: var(--triangle-size) solid transparent;
    border-top: var(--triangle-size) solid var(--hint-color);
}

.hover-right:after {
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-top: var(--triangle-size) solid transparent;
    border-bottom: var(--triangle-size) solid transparent; 
    border-right: var(--triangle-size) solid var(--hint-color);
    border-left: none;
}

.hover-left:after {
    left: auto;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-top: var(--triangle-size) solid transparent;
    border-bottom: var(--triangle-size) solid transparent; 
    border-right: none;
    border-left: var(--triangle-size) solid var(--hint-color);
}

.hover-error {
    font-weight: bold;
    background-color: red;
    color: white;
}

.hover-error:after {
    border-top: var(--triangle-size) solid red !important;
}

.scrollbar-thumb {
    border-radius: 0px !important;
}

.element-updated {
    filter: drop-shadow(0 0 1px var(--main-color));
}

.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--button-color);
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    z-index: 999999999;
}

.cookies__text {
    font-size: 12px;
    font-weight: 400;
}

.cookies__button {
    border: 1px solid #FFFFFF;
    padding: 10px 40px 10px 40px;
    border-radius: 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.25s ease, background-color 0.25s ease;
    font-size: 12px;
}

.cookies__button:hover {    
    background-color: #FFFFFF;
    color: black;
}

.contract-warning {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contract-warning__text {
    font-size: 14px;
}

.lenders-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-blacky);
    margin-bottom: 20px;
}

.decisions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.decisions__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-blacky);
    padding-bottom: 10px;
}

.decisions__header-title {
    font-weight: 500;
    font-size: 18px;
}

.decisions__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.lender__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-left: 4px solid var(--main-color);
    background-color: white;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
    border-radius: 4px;
}

.lender-approved {
    border-color: #1f9710;
}

.lender-denied {
    border-color: #DC1111;
}

.lender__title {
    display: flex;
    gap: 10px;
    align-items: center;
}

.lender__name {
    font-size: 16px;
    font-weight: bold;
}

.lender__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lender__status {
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}

.lender__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    gap: 20px;
}

.program__rules {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
}

.program__rule {
    color: black;
    background-color: #f5f5f5;
    border: 1px solid var(--border-blacky);
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
}

.rules-column {
    flex-direction: column;
    flex-wrap: nowrap;
}

.rollback {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rollback__row {
    display: flex;
    gap: 20px;
}

#references-container {
    margin-bottom: 10px;
}

.reference-delete {
    background-image: url("../images/icons/remove-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 0.25s ease, filter 0.25s ease;
}

.reference-delete:hover {
    filter: drop-shadow(0 0 10px black);
}

.password__difficulty {
    display: flex;
    flex-direction: column;
}

.password__difficulty-requirements {
    font-size: 12px;
    font-weight: 500;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
}

.requirements__text {
    opacity: 0.5;
    transition: opacity 0.25s ease;
}

.requirements__text[completed] {
    opacity: 1;
    color: #43ad19;
}

.password__difficulty-top {
    width: 100%;
    height: 30px;
    position: relative;
}

.difficulty-top__title {
    text-transform: uppercase;
    font-size: 12px;
}

.difficulty-top__line {
    background-color: var(--border-blacky);
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transition: width 0.25s ease;
    display: flex;
}

.difficulty-top__line div {
    width: 20%;
    border-right: 2px solid var(--border-blacky);
}

.difficulty-top__line div:last-child {
    border-right: none;
}

.correct-line {
    width: 0%;
    background-color: rgba(0, 255, 0);
}

.filter-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.block-404 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: #fafafa;
    border: 1px solid var(--border-blacky);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.block-404__img {
    width: 64px;
    height: 64px;
    background-image: url("../images/icons/404-error.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.block-404__header {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: var(--main-color)
}

.block-404__text {
    text-align: center;
    line-height: 25pt;
    color: rgba(0, 0, 0, 0.5);
}

a {
    color: var(--main-color);
    opacity: 1;
}

.dashboard__text {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    padding: 20px;
    padding-right: 90px;
}

.dashboard__text:after {
    content: '';
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../images/icons/arrow-hint.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 48px;
    height: 48px;
    opacity: 0.75;
    animation: arrow-bounce-top 2s ease infinite;
}

.dashboard__empty {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.dashboard__empty-abs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dashboard__empty-img {
    width: 164px;
    height: 100px;
    background-image: url("../images/animated/empty.gif");
    background-size: contain;
    background-repeat: no-repeat;
    filter: grayscale(1)
}

.dashboard__empty-title {
    font-size: 24px;
    font-weight: 600;
}

.dashboard__empty-text {
    font-size: 16px;
    opacity: 0.5;
}

.formviewer {
    padding: 20px;
    width: 100%;
    display: flex;
}

.display-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.help-mark {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 12px;
    font-weight: 700;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    cursor: help;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.25s ease;
}

.help-mark:hover {
    background-color: #eeeeee;
}

.client-section {
    width: 100%;
}

.techworks {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999;
    display: flex;
    --padding-shared: 10px;
    border-radius: 10px;
    overflow: hidden;
    backdrop-filter: blur(20px);
    animation: appear 1s ease;
}

.techworks__spot {
    background-color: rgb(199, 145, 27, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-shared);
}

.techworks__spot-icon {
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/icon-warning-secondary.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.techworks__text {
    background-color: rgba(206, 206, 206, 0.25);
    padding: var(--padding-shared);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.techworks__text-title {
    color: #383838;
    font-size: 18px;
    font-weight: 700;
}

.techworks__text-desc {
    font-size: 14px;
    color: #535353
}

.deal-logs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deal-logs__header {
    font-size: 20px;
    font-weight: 400;
}

.deal-logs__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.logs__item {
    width: 100%;
    padding: 10px;
    background-color: #F9F9F9;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.logs__item-date {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.75;
}

.logs__item-text {
    font-size: 14px;
}

.deal-logs__load {
    font-size: 16px;
    color: var(--main-color);
    cursor: pointer;
}

.deal-logs__load:hover {
    text-decoration: underline;
}

.window-iframe {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.switcher {
    --switcher-height: 20px;
    --switcher-width: calc(var(--switcher-height) * 2);
    --switcher-padding: 3px;
    position: relative;
    padding: var(--switcher-padding);
    width: var(--switcher-width);
    height: var(--switcher-height);
    background-color: var(--button-color);
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.25s ease;
}

.switcher__indicator {
    --size: calc(var(--switcher-height) - var(--switcher-padding) * 2);
    position: absolute;
    left: var(--switcher-padding);
    top: 50%;
    transform: translateY(-50%);
    width: var(--size);
    height: var(--size);
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.25s ease;
}

.checked.switcher {
    background-color: var(--main-color);
}

.checked .switcher__indicator {
    left: calc(var(--switcher-width) - var(--size) - var(--switcher-padding));
}

.image-viewer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    z-index: 10;
    animation: appear 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 60%;
    height: 80%;
}

.image-viewer img {
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.25s ease;
}

.controller {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    background-color: rgba(88, 88, 88, 0.75);
    border-radius: 10px;
    color: white;
    align-items: center;
    z-index: 15;
    backdrop-filter: blur(5px);
}

#image-index {
    width: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
}

.controller-btn {
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
}

.controller-btn:hover {
    color: var(--dealer-color)
}

.balls__particle, .balls__particle-shadow {
    --default-size: 25px;

    position: absolute;
    top: 0;
    left: 0;
    width: var(--default-size);
    height: var(--default-size);
    border-radius: 50%;
    background-color: var(--main-color);
    opacity: 0;
}

.balls__particle-shadow {
    transform: translate(-50%, -50%);
    opacity: 0.05;
    animation: particle-grow 2s ease;
    pointer-events: none;
}

.contract__options {
    display: flex;
    gap: 10px;
    align-items: center;
}

.contract__option {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.option-remove {
    background-image: url('../images/icons/remove-2.png');
}

.option-rename {
    background-image: url('../images/icons/chart-edit.png');
}

.chart__title {
    padding: 5px;
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    font-weight: 300;
    width: 50%;
    transition: border-color 0.25s ease;
}

.chart__title:hover, .chart__title:focus {
    border-color: rgba(0, 0, 0, 0.25);
}

.credit {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f8f8f8;
    padding: 20px;
    border: 1px solid var(--border-blacky);
    border-radius: 10px;
    transition: opacity 0.25s ease;
    opacity: 0;
}

.credit__text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.credit__text-title {
    font-size: 24px;
    font-weight: 500;
}

.credit__text-text {
    font-size: 14px;
    font-weight: 400;
}

.credit__footer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.credit__header {
    display: flex;
    gap: 15px;
    align-items: center;

    border-bottom: 1px solid var(--border-blacky);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.credit__header-category {
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: fit-content;
}

.credit__header-icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100px;
    height: 20px;
}

.credit__header-category:hover, .credit-active {
    background-color: var(--border-blacky);
    text-decoration: underline;
}

.credit__confirm {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
}

.credit__confirm-footer {
    border-top: 1px solid var(--border-blacky);
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.credit__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    cursor: pointer;
}

.credit__option:hover {
    background-color: rgb(0, 0, 0, 0.1);
}

.credit__option:hover .credit__option-icon {
    opacity: 1;
}

.credit__option-title {
    font-size: 18px;
    font-weight: 500;
}

.credit__option-desc {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.75;
}

.credit__option-icon {
    width: 64px;
    height: 64px;
    opacity: 0.5;
}

.credit__option-icon svg {
    width: 100%;
    height: 100%;
}

.aihelp {
    --animation-delay: 2.5s;
    --local-padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    filter: drop-shadow(0 0 4px var(--border-blacky));
    border-radius: 20px;
    width: 25vw;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    gap: 5px;
    overflow: hidden;
    opacity: 0;
    animation: appear 0.25s ease var(--animation-delay) forwards;
}

.aihelp__header {
    position: relative;
    background-color: var(--button-color);
    padding: var(--local-padding);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aihelp__header-content {
    --icon-size: 16px;
    color: #fff;
    position: relative;
    font-size: 14px;
    padding-left: calc(var(--icon-size) + 5px);
}

.aihelp__header-content:before {
    content: '';
    position: absolute;
    left: 0;
    width: var(--icon-size);
    height: var(--icon-size);
    background-image: url("../images/icons/icon-help.svg");
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(1);
}

.aihelp__header-close {
    position: absolute;
    right: var(--local-padding);
    top: var(--local-padding);
    width: 16px;
    height: 16px;
    filter: invert(1);
    background-image: url("../images/icons/close.png");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.25s ease;
}

.aihelp__header-close:hover {
    background-color: var(--border-blacky);
}

.aihelp__body {
    padding: calc(var(--local-padding) * 2);
    font-size: 14px;
    font-weight: 300;
    animation: item-appear 0.25s ease var(--animation-delay);
    text-align: center;
}

.aihelp-remove {
    animation: ai-remove 0.5s ease;
}

/* ========================================================== */

/* * FLEX CONTAINERS */

.flx-row {
    display: flex;
    flex-direction: row !important;
}

.flx-center {
    align-items: center;
    justify-content: space-between;
}

.flx-center-abs {
    align-items: center;
    justify-content: center;
}

.flx-align {
    align-items: center;
}

.flx-justify {
    justify-content: space-between;
}

.flx-clm {
    display: flex;
    flex-direction: column;
}

.flx-start {
    align-items: flex-start;
}

.flx-end {
    display: flex;
    justify-content: flex-end;
}

.flx-wrap {
    flex-wrap: wrap;
}

.flx-nowrap {
    flex-wrap: nowrap;
}

/* * GAPS */

.gap-sm {
    gap: 5px;
}

.gap-md {
    gap: 10px;
}

.gap-lg {
    gap: 15px;
}

.gap-xl {
    gap: 20px;
}

.gap-xxl {
    gap: 30px;
}

.row-gap-sm {
    row-gap: 5px;
}

.row-gap-md {
    row-gap: 10px;
}

.row-gap-lg {
    row-gap: 15px;
}

.row-gap-xl {
    row-gap: 20px;
}

.row-gap-xxl {
    row-gap: 30px;
}



/* * INPUT SIZES */

.sz-fit {
    width: fit-content !important;
}

.sz-fill {
    width: 100% !important;
}

.sz-auto {
    width: auto !important;
}

.sz-mr {
    width: 50px !important;
}

.sz-xs {
    width: 100px !important;
}

.sz-sm {
    width: 150px !important;
}

.sz-md {
    width: 200px !important;
}

.sz-lg {
    width: 250px !important;
}

.sz-xl {
    width: 300px !important;
}

.sz-xxl {
    width: 500px !important;
}

/* * FONT SIZES */

.fz-mr {
    font-size: 10px;
}

.fz-sm {
    font-size: 12px;
}

.fz-md {
    font-size: 14px;
}

.fz-lg {
    font-size: 16px;
}

.fz-xl {
    font-size: 18px;
}

.fz-xxl {
    font-size: 20px;
}

/* * FONT WEIGHT */

.fw-light {
    font-weight: 300;
}

.fw-reg {
    font-weight: 400;
}

.fw-semi {
    font-weight: 500;
}

.fw-bold {
    font-weight: 700;
}

.fw-extra {
    font-weight: 900;
}

/* * MARGIN */

.mg-r-sm {
    margin-right: 5px;
}

.mg-r-md {
    margin-right: 10px;
}

.mg-r-lg {
    margin-right: 15px;
}

.mg-r-xl {
    margin-right: 20px;
}

.mg-b-sm {
    margin-bottom: 5px;
}

.mg-b-md {
    margin-bottom: 10px;
}

.mg-b-lg {
    margin-bottom: 15px;
}

.mg-l-sm {
    margin-left: 5px;
}

.mg-l-md {
    margin-left: 10px;
}

.mg-l-lg {
    margin-left: 15px;
}

.mg-l-xl {
    margin-left: 20px;
}

.mg-t-sm {
    margin-top: 5px;
}

.mg-t-md {
    margin-top: 10px;
}

.mg-t-lg {
    margin-top: 15px;
}

/* * PADDING */

.pd-sm {
    padding: 5px;
}

.pd-md { 
    padding: 10px;
}

.pd-lg {
    padding: 15px;
}

.pd-xl {
    padding: 20px;
}

.pd-xxl {
    padding: 30px;
}

/* * GENERIC STYLES */

.style-border-bottom {
    border-bottom: 1px solid var(--border-blacky);
    padding-bottom: 10px;
}

/* * TEXT ETC */

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-ghost {
    opacity: 0;
}

/* * INPUT STYLES */

.input {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}

.input-wrapper[display] {
    cursor: help;
}

.input-wrapper input {
    padding-right: 30px;
}

.input-section {
    position: relative;
    display: flex;
    justify-content: right;
    gap: 5px;
    width: var(--standart-input-width);
    height: 100%;
}

.input-section input {
    width: 100%;
}

.input-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.input__entry, .input__combobox, .input__textarea, .input__calendar, .input__color, .input__entry-default {
    background-color: rgb(253, 253, 253);
    border: 1px solid var(--border-blacky);
    padding: 10px;
    width: var(--standart-input-width);
    min-height: var(--standart-input-height);
    font-size: 12px;
    font-weight: 500;
    transition: border 0.25s;
    appearance: textfield;
    -moz-appearance: textfield;
    border-radius: 4px;
    position: relative;
}

.calendar {
    display: flex;
    background-image: url("../images/icons/calendar.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 24px;
    height: 24px;
    align-self: center;
    cursor: pointer;
}

.calendar .input__calendar {
    width: 100%;
}

.input__calendar-element {
    cursor: pointer;
    background: none;
    color: transparent;
    caret-color: transparent;
    border: none;
    outline: none;
}

.input__calendar-element:disabled {
    color: rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.calendar__image:hover, .input__functions-image:hover, .input__functions-arrow:hover {
    background-color: rgba(200, 200, 200);
}

.calendar__image {
    position: absolute;
    background-image: url("../images/icons/calendar.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: rgba(173, 173, 173, 0.5);
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    width: 32px;
    height: var(--standart-input-height);
    right: 0px;
    transition: background-color 0.25s ease;
}

.calendar.--options {
    position: relative;
    background-image: none;
    align-self: stretch;
    width: 250px;
    height: auto;
    background-size: 24px;
}

.input__text {
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
}

.input__text.--column {
    font-size: 12px;
    margin-bottom: 5px;
}

.input__functions {
    position: absolute;
    right: 0;
    display: flex;
    height: var(--standart-input-height);
    justify-content: flex-end;
}

.input__functions-arrows {
    width: 32px;
    height: 100%;
    background-color: rgba(173, 173, 173, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 32px;
}

.input__functions-arrow {
    cursor: pointer;
    width: 100%;
    height: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: background-color 0.25s ease;
    border-right: 1px solid var(--border-blacky);
    background-image: url("../images//icons/vertical-arrow-up.png");
}

.input__functions-arrow:first-child {
    border-bottom: 1px solid var(--border-blacky);

}

.input__functions-arrow.--arrow-down {
    background-image: url("../images//icons/vertical-arrow-down.png");
}

.input__dots {
    position: absolute;
    background-image: url("../images/icons/dots.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    right: 0;
    opacity: 0.5;
    transition: opacity 0.1s linear;
}

.input__dots:hover {
    opacity: 1;
}

input, textarea {
    font-family: inherit;
}

.popup__input-row input {
    height: auto;
}

ctm-select {
    position: relative;
    padding-right: 30px;
    font-size: 14px;
    transition: border 0.25s ease;
    cursor: pointer;
    text-wrap: nowrap;
    width: var(--standart-input-width);
    height: var(--standart-input-height);
    display: flex;
    align-items: center;
    
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

ctm-select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url("../images/icons/vertical-arrow-down.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.select-focus, input:focus, textarea:focus, .jscolor-active {
    outline: none;
    border-color: var(--main-color);
}

.checkbox {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
}

.checkbox__element {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-right: 10px;
    transition: border-color 0.25s ease, filter 0.25s ease;
    position: relative;
}

.checkbox__element::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../images/icons/login-success.png");
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(1);
    opacity: 0;
    transition: opacity 0.25s ease;
}

.checkbox__element:hover {
    border-color: rgba(0, 0, 0, 0.3);
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.25))
}

.checked::after {
    opacity: 1;
}

.checkbox__text {
    font-size: 14px;
    font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[source-type=number], .custom-wrapper .input__entry {
    text-align: right;
    padding-right: 30px;
}

input[phone=true] {
    text-align: left !important;
}

options {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--border-blacky);
    overflow: hidden;
    animation: options-appear 0.25s ease;
    max-height: 20vh;
    overflow-y: auto;
}

options::-webkit-scrollbar {
    width: 5px;
    background: none;
}

options::-webkit-scrollbar-track {
    background: #f1f1f1;
}

options::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

.ctm-option {
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.25s ease;
}

.ctm-option:hover {
    background-color: var(--button-color);
    color: white;
}

input:disabled, ctm-select[disabled], ctm-checkbox[disabled] {
    background-color: #e7e7e7;
    color: rgb(100, 100, 100);
    font-weight: 800;
    border-color: rgba(0, 0, 0, 0.05);
}

.input__radio {
    display: flex;
    border-radius: 5px;
    border: 1px solid var(--border-blacky);
    align-items: center;
    height: var(--standart-input-height);
    overflow-x: scroll;
}

.input__radio-option {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    height: 100%;
    padding: 10px;
    transition: background-color 0.25s ease, color 0.25s ease;
    background-color: white;
    min-width: 75px;
    text-transform: capitalize;
}

.input__radio-option:first-child {
    border-radius: 4px 0 0 4px;
}

.input__radio-option:last-child {
    border: none;
    border-radius: 0px 4px 4px 0;
}

.input__radio-option:hover, .radio-active {
    background-color: var(--button-color);
    color: white;
}

.input__functions-image {
    background-image: url("../images/icons/add.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    cursor: pointer;
    background-color: rgb(185, 185, 185);
    width: 32px;
    height: 100%;
    transition: background-color 0.25s ease;
}

.input__functions-image:last-child {
    border-radius: 0 4px 4px 0;
}

.image-delete {
    background-image: url("../images/icons/close.png");
}

.input-warning {
    border: 1px solid rgba(255, 0, 0) !important;
}

.error-notice {
    color: red;
    font-size: 10px;
    font-weight: 600;
    animation: error-popup 0.25s ease;
    margin-top: 5px;
}

/* * VARIOUS STYLES */

.support {
    opacity: 0.75;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border: 1px solid var(--border-blacky);
    border-radius: 50%;
    filter: drop-shadow(0 0 20px var(--border-blacky));
    background-color: var(--main-color);
    cursor: pointer;
    transition: background-color 0.25s ease, opacity 0.25s ease;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;

    --support-color: rgb(250, 250, 250);
}

.support:hover {
    background-color: var(--button-color);
}

.support-active {
    opacity: 1;
    background-color: var(--support-color) !important;
    cursor: auto;
}

.support__wrapper-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    background-image: url("../images/icons/support-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 0.25s ease;
}

.support-item {
    position: relative;
    display: none;
    opacity: 0;
    width: 100%;
}

.support__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 10px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.support__wrapper {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.support-active .support__wrapper {
    display: none;
}

.support__text {
    text-align: center;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background-color: #fcfcfc;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    border-radius: 4px;
}

.input__file {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
    height: var(--standart-input-height);
    position: relative;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 5px;
    background-color: white;
    cursor: pointer;
}

.input__file:after {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/file-upload-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    filter: invert(1);
}

.input__file:before {
    content: 'click or drop file(s) to attach';
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.input__file:hover:before {
    opacity: 1;
    animation: filehint-slide 0.25s ease;
}